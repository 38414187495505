$digital: #385dff;
$grey_10: #dfe1e8;
$scarlet: #e23b3e;

.pin-field {
  font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border: 1px solid $grey_10;
  border-right: none;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: color, border, box-shadow, transform;
  font-size: 1.2rem;
  height: 3em;
  width: 3em;

  @media screen and (max-width: 318px) {
    height: 2em;
    width: 2em;
  }

  &:first-of-type {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  &:last-of-type {
    border-radius: 0 0.5rem 0.5rem 0;
    border-right: 1px solid $grey_10;
  }

  &:focus {
    border-color: $digital;
    box-shadow: 0 0 0.25rem rgba($digital, 0.5);
    outline: none;

    & + .pin-field {
      border-left-color: $digital;
    }
  }

  &.error,
  &:invalid {
    animation: shake 3 linear 75ms;
    border-color: $scarlet;
    box-shadow: 0 0 0.25rem rgba($scarlet, 0.5);

    & + .pin-field {
      border-left-color: $scarlet;
    }
  }

  &.complete {
    animation: grow linear 160ms;
    font-weight: 600;
  }
}

@keyframes shake {
  from {
    transform: translateY(-5%);
  }
  to {
    transform: translateY(5%);
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: translateY(1);
  }
}
